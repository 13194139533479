import React from 'react';
import PasswordModificationContainer from './PasswordModificationContainer';
import { ResetOrChangePasswordEnum } from '../Portal/Portal';
import { PasswordResetData } from '../Login/LoginContext';

type Props = {
	passwordResetData: PasswordResetData;
};
const NeedResetPassword: React.FC<Props> = ({ passwordResetData }) => {
	const message =
		passwordResetData.daysBeforePasswordExpiration !== undefined &&
		passwordResetData.daysBeforePasswordExpiration >= 0
			? `Your password will expire in ${passwordResetData.daysBeforePasswordExpiration} day(s). Please reset it.`
			: 'Your password has expired. Please reset it.';

	return (
		<PasswordModificationContainer
			resetOrChangePassword={ResetOrChangePasswordEnum.Change} // Reset need a token
			message={message}
		/>
	);
};

export default NeedResetPassword;
