import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { displaySuccess, displayError } from '@tunz/message-displayer';

import { useWebBrand } from '../WebBrand';
import { executeChangePassword, executeResetPassword } from '../../Services/PasswordService';
import ErrorDisplay from '../ErrorDisplay';
import { ResetOrChangePasswordEnum } from '../Portal/Portal';
import PasswordForm from './PasswordForm';
import usePasswordResetInfo from '../Login/usePasswordResetInfo';
import { removeForcePasswordChange } from './ForcePasswordChange';

const LETTER_REGEX = /[a-z]+/i;
const NUMBER_REGEX = /[0-9]+/;
const SPACE_REGEX = /\s/;

export type Validation = {
	isLengthValid: boolean;
	isLetterValid: boolean;
	isNumberValid: boolean;
	isSpaceAbsent: boolean | undefined;
	isConfSame: boolean | undefined;
};

const initialValidation: Validation = {
	isLengthValid: false,
	isLetterValid: false,
	isNumberValid: false,
	isSpaceAbsent: undefined,
	isConfSame: undefined,
};

type Props = {
	token?: null | string;
	resetOrChangePassword: ResetOrChangePasswordEnum;
	message?: string;
};

const PasswordModificationContainer: React.FC<Props> = (props) => {
	const [oldPassword, setOldPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
	const [otp, setOtp] = useState<string>('');
	const [validation, setValidation] = useState(initialValidation);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const { webBrand } = useWebBrand();
	const { skipResetPassword, passwordResetData } = usePasswordResetInfo();

	const { token, resetOrChangePassword, message } = props;

	const getFormValidation = useMemo((): boolean => {
		setErrorMessage('');
		if (resetOrChangePassword === ResetOrChangePasswordEnum.Change) {
			if (oldPassword === '') {
				return false;
			}
			if (oldPassword === newPassword) {
				setErrorMessage('Old and new passwords must be different.');
				return false;
			}
		}
		return !(
			Object.values(validation).includes(false) ||
			Object.values(validation).includes(undefined)
		);
	}, [oldPassword, newPassword, newPasswordConfirm]);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const input = e.target.name;
		const { value } = e.currentTarget;

		if (input === 'oldPassword') {
			setOldPassword(value);
		}

		if (input === 'newPassword') {
			setValidation({
				...validation,
				isLengthValid: value.length >= 8,
				isLetterValid: !!value.match(LETTER_REGEX),
				isNumberValid: !!value.match(NUMBER_REGEX),
				isSpaceAbsent: !value.match(SPACE_REGEX),
			});
			setNewPassword(value);
		}

		if (input === 'newPasswordConfirm') {
			if (value.length >= newPassword.length) {
				setValidation({
					...validation,
					isConfSame: value === newPassword,
				});
			}
			setNewPasswordConfirm(value);
		}

		if (input === 'otp') {
			setOtp(value);
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault();
		setErrorMessage('');
		try {
			switch (resetOrChangePassword) {
				case ResetOrChangePasswordEnum.Change:
					await executeChangePassword({
						oldPassword,
						newPassword,
						newPasswordConfirm,
						otp,
					});
					displaySuccess('Password successfully changed!');
					removeForcePasswordChange();
					break;
				case ResetOrChangePasswordEnum.Reset:
					if (token === undefined || token === null) {
						displayError(
							'Missing token or expired password request. Please request a new password reset.',
						);
					} else {
						await executeResetPassword(token, {
							newPassword,
							newPasswordConfirm,
							otp,
						});
						displaySuccess('Password successfully reset!');
					}
					break;
				default:
					break;
			}
			window.location.href = '/'; // redirect to Home page with refresh to reload local storage
		} catch (err) {
			const error = err as Error;
			setErrorMessage(error.message);
		} finally {
			setOtp('');
		}
	};

	return (
		<Container className="mt-4">
			<Row>
				<Col md={{ span: 4, offset: 4 }}>
					<Card
						style={{ width: '400px' }}
						text={passwordResetData?.forcePasswordReset ? 'danger' : ''}
					>
						<Card.Body>
							<Card.Title>
								<div className="brand-area">
									<img
										src={webBrand.iconUrl}
										alt={webBrand.name}
										className="brand-img"
									/>
									<div className="brand-title mt-4">{webBrand.name}</div>
									<div className="brand-sub-title mt-2">{webBrand.subtitle}</div>
								</div>
							</Card.Title>
							<Card.Body>
								{message && <h5>{message}</h5>}
								<Card.Text>
									Password should meet the following requirements:
								</Card.Text>
								<ListGroup>
									<ListGroup.Item
										variant={validation.isLengthValid ? 'success' : 'light'}
									>
										Min 8 characters.
									</ListGroup.Item>
									<ListGroup.Item
										variant={validation.isLetterValid ? 'success' : 'light'}
									>
										At least 1 letter.
									</ListGroup.Item>
									<ListGroup.Item
										variant={validation.isNumberValid ? 'success' : 'light'}
									>
										At least 1 number.
									</ListGroup.Item>
									<ListGroup.Item
										variant={
											validation.isSpaceAbsent === undefined ||
											validation.isSpaceAbsent
												? 'light'
												: 'danger'
										}
									>
										No space.
									</ListGroup.Item>
								</ListGroup>
							</Card.Body>
							<Form className="my-4" onSubmit={handleSubmit}>
								<PasswordForm
									resetOrChangePassword={resetOrChangePassword}
									oldPassword={oldPassword}
									onChange={onChange}
									validation={validation}
									newPassword={newPassword}
									newPasswordConfirm={newPasswordConfirm}
									otp={otp}
								/>
								<div className="d-grid gap-2">
									<Button
										variant="primary"
										type="submit"
										id="submitPasswordRequestButton"
										data-testid="submitPasswordRequest-button"
										size="lg"
										disabled={!getFormValidation}
									>
										{resetOrChangePassword === ResetOrChangePasswordEnum.Change
											? 'Change your Password'
											: 'Reset Your Password'}
									</Button>
									{passwordResetData?.needsPasswordReset &&
										!passwordResetData?.forcePasswordReset && (
											<Button
												variant="secondary"
												id="skipResetPassword"
												data-testid="skipResetPassword-button"
												size="lg"
												onClick={skipResetPassword}
											>
												Skip and Reset your password later.
											</Button>
										)}
								</div>
							</Form>
							{errorMessage && <ErrorDisplay message={errorMessage} />}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default PasswordModificationContainer;
