import React, { useEffect, useState } from 'react';
import { attachTokenUpdateCallback, detachTokenUpdateCallback, removeToken } from '@tunz/http';
import { Loading } from '@tunz/ifs-react-lib';

import { displayError } from '@tunz/message-displayer';
import LoginContext, { passwordResetInitialValue } from './LoginContext';
import { doLogin, ResponseData } from '../../Services/UserService/LoginRequest';
import { loadLoginInfo } from '../../Services/UserService/LoginPersistance';
import User from '../../Services/UserService/User';
import { useWebBrand } from '../WebBrand';
import { doLogout } from '../../Services/UserService/LogoutRequest';
import {
	forcePasswordChange,
	isForcePasswordChange,
	removeForcePasswordChange,
} from '../Password/ForcePasswordChange';

type LoginProviderProps = {
	children?: React.ReactNode;
	supportUrl?: string;
};

const LoginProvider: React.FC<LoginProviderProps> = ({
	children,
	supportUrl,
}: LoginProviderProps) => {
	const [userInfo, setUserInfo] = useState<User | undefined>(undefined);
	const { webBrand, switchWebBrand } = useWebBrand();
	const [error, setError] = useState<string>();
	const [loadingSession, setLoadingSession] = useState(false);
	const [passwordResetData, setPasswordResetData] = useState(passwordResetInitialValue);

	// Register to be notified each time the token changes
	useEffect(() => {
		setLoadingSession(true);

		loadLoginInfo()
			.then((t) => setUserInfo(t))
			.finally(() => setLoadingSession(false));

		const updateUserWithToken = (token: string | undefined): void => {
			setUserInfo((u) => (u ? u.updateWithToken(token) : u));
		};

		attachTokenUpdateCallback(updateUserWithToken);

		// Check if the user is forced to change the password in case of page refresh
		if (isForcePasswordChange()) {
			setPasswordResetData({
				needsPasswordReset: true,
				forcePasswordReset: true,
				daysBeforePasswordExpiration: 90,
			});
		}

		// Return the function to call for cleaning up the effect
		// Like we used to do in componentWillUnmount
		return (): void => {
			detachTokenUpdateCallback(updateUserWithToken);
		};
	}, []);

	if (loadingSession) {
		return <Loading message="Loading your session..." />;
	}

	const handlePasswordResetData = (data: ResponseData): void => {
		if (data) {
			const { needsPasswordReset, forcePasswordReset, daysBeforePasswordExpiration } =
				data.data;
			setPasswordResetData({
				needsPasswordReset,
				forcePasswordReset,
				daysBeforePasswordExpiration,
			});

			// Use local storage in case of page refresh
			if (forcePasswordReset) {
				forcePasswordChange();
			} else {
				removeForcePasswordChange();
			}
		}
	};

	const performLogin = async (
		userName: string,
		password: string,
		otp: string,
		branch: string,
	): Promise<void> => {
		try {
			setError(undefined);
			const login = await doLogin({
				login: userName.trim(),
				password: password.trim(),
				otp,
				branch,
				application: 'BACKOFFICE',
			});
			const { token } = login.headers;
			setUserInfo(new User(userName, token));
			handlePasswordResetData(login.data);
		} catch (e) {
			const loginError = e as Error;
			setUserInfo(undefined);
			setError(loginError.message);
		}
	};

	const performLogout = async (): Promise<void> => {
		setError(undefined);
		try {
			await doLogout();
		} catch (e) {
			const logoutError = e as Error;
			displayError(logoutError.message);
		}
		setUserInfo(undefined);
		removeToken();
		removeForcePasswordChange();
	};

	if (userInfo && userInfo.metadata && `${userInfo.metadata.branchId}` !== webBrand.id) {
		switchWebBrand(`${userInfo.metadata.branchId}`);
	}

	const skipResetPassword = (): void => {
		setPasswordResetData({ ...passwordResetData, needsPasswordReset: false });
	};

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const value = {
		isLoggedIn: userInfo !== undefined,
		userInfo,
		error,
		performLogin,
		performLogout,
		passwordResetData,
		skipResetPassword,
		supportUrl,
		setError,
	};

	return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};

export default LoginProvider;
