const FORCE_PASSWORD_CHANGE_KEY = 'FORCE-PASSWORD-CHANGE';

export const isForcePasswordChange = (): boolean => {
	const storedForcePasswordChange = window.localStorage.getItem(FORCE_PASSWORD_CHANGE_KEY);
	return storedForcePasswordChange === 'true';
};

export const forcePasswordChange = (): void => {
	window.localStorage.setItem(FORCE_PASSWORD_CHANGE_KEY, 'true');
};

export const removeForcePasswordChange = (): void => {
	window.localStorage.removeItem(FORCE_PASSWORD_CHANGE_KEY);
};
